.element {
  background-color: #262626;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}
body{
  overflow-x: hidden;
}
.element .div {
  background-color: #262626;
  height: 5285px;
  position: relative;
  width: 1920px;
}

.element .overlap {
  height: 2088px;
  left: 960px;
  position: absolute;
  top: 0;
  width: 960px;
}

.element .rectangle {
  background-color: #15d199;
  height: 1233px;
  left: 0;
  position: absolute;
  top: 0;
  width: 960px;
}

.element .text-wrapper {
  color: #ffffff;
  font-family: "Neue Haas Grotesk Text Pro-55Roman", Helvetica;
  font-size: 28px;
  font-weight: 400;
  height: 34px;
  left: 696px;
  letter-spacing: -0.39px;
  line-height: normal;
  position: absolute;
  top: 69px;
  width: 144px;
}

.element .img {
  height: 1234px;
  left: 138px;
  position: absolute;
  top: 0;
  width: 822px;
}

.element .onboarding {
  background-color: #ffffff;
  height: 860px;
  left: 0;
  position: absolute;
  top: 1228px;
  width: 959px;
}

.element .overlap-group {
  background-image: url(../../../static/img/mask-group-3.png);
  background-size: 100% 100%;
  height: 860px;
  left: -1px;
  position: relative;
  width: 960px;
}

.element .group {
  height: 33px;
  left: 88px;
  position: relative;
  top: 159px;
  width: 215px;
}

.element .text-wrapper-2 {
  color: #000000;
  font-family: "Neue Haas Grotesk Text Pro-65Medium", Helvetica;
  font-size: 32px;
  font-weight: 500;
  height: 33px;
  left: 0;
  letter-spacing: -1.04px;
  line-height: 32.9px;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.element .text-wrapper-3 {
  color: #ffffff;
  font-family: "Neue Haas Grotesk Text Pro-55Roman", Helvetica;
  font-size: 28px;
  font-weight: 400;
  height: 34px;
  left: 768px;
  letter-spacing: -0.39px;
  line-height: normal;
  position: absolute;
  top: 69px;
  width: 164px;
}

.element .hero {
  height: 611px;
  left: 120px;
  position: absolute;
  top: 315px;
  width: 743px;
}

.element .p {
  color: #ffffff;
  font-family: "Neue Haas Grotesk Text Pro-55Roman", Helvetica;
  font-size: 26px;
  font-weight: 400;
  left: 0;
  letter-spacing: -0.04px;
  line-height: 39.5px;
  position: absolute;
  top: 294px;
  width: 743px;
}

.element .text-wrapper-4 {
  color: #ffffff;
  font-family: "Neue Haas Grotesk Text Pro-65Medium", Helvetica;
  font-size: 96.1px;
  font-weight: 500;
  height: 230px;
  left: 0;
  letter-spacing: -2.39px;
  line-height: 115px;
  position: absolute;
  top: 8px;
  width: 743px;
}

.element .UIX {
  height: 56px;
  left: 120px;
  position: absolute;
  top: 1049px;
  width: 160px;
}

.element .overlap-2 {
  background-color: #5f30c5;
  border-radius: 28px;
  height: 56px;
  position: relative;
  width: 158px;
}

.element .text-wrapper-5 {
  color: #ffffff;
  font-family: "Neue Haas Grotesk Display Pro-75Bold", Helvetica;
  font-size: 23.1px;
  font-weight: 700;
  height: 33px;
  left: 64px;
  letter-spacing: 0.46px;
  line-height: 32.9px;
  position: absolute;
  top: 12px;
  white-space: nowrap;
  width: 66px;
}

.element .overlap-group-wrapper {
  background-color: #f8f8f8;
  border-radius: 23.06px;
  height: 46px;
  left: 6px;
  position: absolute;
  top: 5px;
  width: 46px;
}

.element .overlap-group-2 {
  height: 21px;
  left: 12px;
  position: relative;
  top: 12px;
  width: 21px;
}

.element .line {
  height: 21px;
  left: 8px;
  position: absolute;
  top: 0;
  width: 5px;
}

.element .line-2 {
  height: 4px;
  left: 0;
  position: absolute;
  top: 9px;
  width: 21px;
}

.element .WD {
  height: 56px;
  left: 294px;
  position: absolute;
  top: 1049px;
  width: 304px;
}

.element .overlap-3 {
  background-color: #4900ff;
  border-radius: 28px;
  height: 56px;
  position: relative;
  width: 302px;
}

.element .text-wrapper-6 {
  color: #ffffff;
  font-family: "Neue Haas Grotesk Display Pro-75Bold", Helvetica;
  font-size: 23.1px;
  font-weight: 700;
  height: 33px;
  left: 64px;
  letter-spacing: 0.46px;
  line-height: 32.9px;
  position: absolute;
  top: 12px;
  white-space: nowrap;
}

.element .SM {
  height: 56px;
  left: 612px;
  position: absolute;
  top: 1049px;
  width: 180px;
}

.element .overlap-4 {
  background-color: #eb4132;
  border-radius: 28px;
  height: 56px;
  position: relative;
  width: 178px;
}

.element .text-wrapper-7 {
  color: #ffffff;
  font-family: "Neue Haas Grotesk Display Pro-75Bold", Helvetica;
  font-size: 23.1px;
  font-weight: 700;
  height: 33px;
  left: 65px;
  letter-spacing: 0.46px;
  line-height: 32.9px;
  position: absolute;
  top: 12px;
  white-space: nowrap;
}

.element .group-2 {
  height: 64px;
  left: 120px;
  position: absolute;
  top: 55px;
  width: 251px;
}

.element .onboarding-2 {
  background-color: #15d199;
  height: 860px;
  left: 0;
  position: absolute;
  top: 1228px;
  width: 960px;
}

.element .div-wrapper {
  height: 33px;
  left: 120px;
  position: absolute;
  top: 55px;
  width: 187px;
}

.element .text-wrapper-8 {
  color: #000000;
  font-family: "Neue Haas Grotesk Text Pro-65Medium", Helvetica;
  font-size: 24px;
  font-weight: 500;
  height: 33px;
  left: 0;
  letter-spacing: -0.04px;
  line-height: 32.9px;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.element .group-3 {
  height: 718px;
  left: 131px;
  position: absolute;
  top: 88px;
  width: 686px;
}

.element .element-login {
  background-color: #151515;
  border: 10.78px solid;
  border-color: #ffffff;
  border-radius: 34.35px;
  box-shadow: 27.59px 20.69px 110.34px #cccccc2e;
  height: 704px;
  left: 361px;
  overflow: hidden;
  position: absolute;
  top: -11px;
  width: 336px;
}

.element .overlap-5 {
  height: 706px;
  position: relative;
  width: 314px;
}

.element .UI-btn {
  box-shadow: 0px 3.43px 17.17px #0000001a, 0px 1.72px 3.43px #0000001a;
  height: 48px;
  left: 92px;
  position: absolute;
  top: 603px;
  width: 137px;
}

.element .overlap-6 {
  background-color: #1616cc;
  border-radius: 5.15px;
  height: 48px;
  left: -64px;
  position: relative;
  width: 270px;
}

.element .IC-chevronright {
  height: 21px;
  left: 149px;
  position: absolute;
  top: 14px;
  width: 21px;
}

.element .overlap-group-3 {
  height: 13px;
  left: 4px;
  position: relative;
  top: 3px;
  width: 13px;
}

.element .vector {
  height: 1px;
  left: 0;
  position: absolute;
  top: 6px;
  width: 13px;
}

.element .vector-2 {
  height: 13px;
  left: 6px;
  position: absolute;
  top: 0;
  width: 7px;
}

.element .text-wrapper-9 {
  color: #ffffff;
  font-family: "Aktiv Grotesk-Bold", Helvetica;
  font-size: 15.5px;
  left: 96px;
  line-height: normal;
  position: absolute;
  top: 13px;
  width: 46px;
}

.element .group-4 {
  height: 195px;
  left: 26px;
  position: absolute;
  top: 178px;
  width: 270px;
}

.element .form-list {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  gap: 8.59px;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 90px;
}

.element .UI-form-default {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 6.87px;
  position: relative;
}

.element .form {
  align-items: center;
  background-color: #ffffff;
  box-shadow: inset 0px -0.43px 0px #12121d1a;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 13.74px;
  overflow: hidden;
  padding: 13.74px 0px;
  position: relative;
}

.element .img-2 {
  height: 20.61px;
  position: relative;
  width: 20.61px;
}

.element .fill {
  height: 20.61px;
  position: relative;
  width: 238.15px;
}

.element .text-wrapper-10 {
  color: #2f2f2f4c;
  font-family: "Inter-Regular", Helvetica;
  font-size: 15.5px;
  height: 21px;
  left: 0;
  line-height: normal;
  position: absolute;
  top: 0;
  width: 236px;
}

.element .divider {
  background-color: #2f2f2f4c;
  height: 1px;
  left: 0;
  position: absolute;
  top: 65px;
  width: 270px;
}

.element .social {
  align-items: center;
  display: inline-flex;
  gap: 13.74px;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
}

.element .img-wrapper {
  background-color: #ffffff;
  border: 0.43px solid;
  border-color: #2f2f2f1a;
  border-radius: 24.04px;
  height: 48.09px;
  position: relative;
  width: 48.09px;
}

.element .img-3 {
  height: 21px;
  left: 13px;
  position: absolute;
  top: 13px;
  width: 21px;
}

.element .title {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  gap: 6.87px;
  left: 26px;
  position: absolute;
  top: 127px;
}

.element .text-wrapper-11 {
  color: #2f2f2f;
  font-family: "Inter-Bold", Helvetica;
  font-size: 20.6px;
  height: 24.93px;
  line-height: normal;
  margin-top: -0.86px;
  position: relative;
  width: 237.24px;
}

.element .UI-title-nav {
  height: 38px;
  left: 26px;
  position: absolute;
  top: 64px;
  width: 270px;
}

.element .IC-close-wrapper {
  -webkit-backdrop-filter: blur(17.17px) brightness(100%);
  backdrop-filter: blur(17.17px) brightness(100%);
  background-color: #2f2f2f0d;
  border-radius: 18.89px;
  height: 38px;
  width: 38px;
}

.element .IC-close {
  height: 21px;
  left: 9px;
  position: relative;
  top: 9px;
  width: 21px;
}

.element .vector-wrapper {
  background-image: url(../../../static/img/vector-1.svg);
  background-size: 100% 100%;
  height: 12px;
  left: 5px;
  position: relative;
  top: 4px;
  width: 12px;
}

.element .vector-3 {
  height: 12px;
  left: 0;
  position: absolute;
  top: 0;
  width: 12px;
}

.element .create-account {
  background-color: #151515;
  height: 706px;
  left: 0;
  position: absolute;
  top: 0;
  width: 314px;
}

.element .div-2 {
  height: 33px;
  left: 0;
  position: absolute;
  top: 0;
  width: 314px;
}

.element .time {
  color: transparent;
  font-family: "SF Pro Text-Semibold", Helvetica;
  font-size: 11.4px;
  font-weight: 400;
  left: 16px;
  letter-spacing: -0.23px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 10px;
  white-space: nowrap;
  width: 41px;
}

.element .battery {
  height: 9px;
  left: 284px;
  position: absolute;
  top: 13px;
  width: 18px;
}

.element .cap {
  height: 3px;
  left: 1272px;
  position: absolute;
  top: 1330px;
  width: 1px;
}

.element .wifi {
  height: 8px;
  left: 1523px;
  position: absolute;
  top: 1340px;
  width: 12px;
}

.element .cellular-connection {
  height: 8px;
  left: 1507px;
  position: absolute;
  top: 1340px;
  width: 13px;
}

.element .time-2 {
  color: #ffffff;
  font-family: "SF Pro Text-Semibold", Helvetica;
  font-size: 11.4px;
  font-weight: 400;
  left: 16px;
  letter-spacing: -0.23px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 10px;
  white-space: nowrap;
  width: 41px;
}

.element .capacity-wrapper {
  border: 0.76px solid;
  border-color: #ffffff59;
  border-radius: 2.02px;
  height: 9px;
  left: 0;
  position: absolute;
  top: 0;
  width: 17px;
}

.element .capacity {
  background-color: #ffffff;
  border-radius: 1.01px;
  height: 6px;
  left: 1px;
  position: relative;
  top: 1px;
  width: 14px;
}

.element .cap-2 {
  height: 3px;
  left: 17px;
  position: absolute;
  top: 3px;
  width: 1px;
}

.element .wifi-2 {
  height: 8px;
  left: 269px;
  position: absolute;
  top: 13px;
  width: 12px;
}

.element .cellular-connection-2 {
  height: 8px;
  left: 252px;
  position: absolute;
  top: 13px;
  width: 13px;
}

.element .group-5 {
  height: 45px;
  left: 21px;
  position: absolute;
  top: 233px;
  width: 266px;
}

.element .overlap-group-4 {
  height: 45px;
  position: relative;
  width: 264px;
}

.element .page-title {
  color: #ffffff;
  font-family: "Archivo", Helvetica;
  font-size: 12.1px;
  font-weight: 500;
  left: 19px;
  letter-spacing: -0.15px;
  line-height: normal;
  position: absolute;
  top: 15px;
  white-space: nowrap;
  width: 56px;
}

.element .span {
  color: #ffffff;
  font-family: "Archivo", Helvetica;
  font-size: 12.1px;
  font-weight: 500;
  letter-spacing: -0.15px;
}

.element .rectangle-2 {
  border: 0.38px solid;
  border-color: #fcfcfe;
  height: 45px;
  left: 0;
  position: absolute;
  top: 0;
  width: 264px;
}

.element .overlap-wrapper {
  height: 45px;
  left: 21px;
  position: absolute;
  top: 292px;
  width: 266px;
}

.element .overlap-7 {
  height: 47px;
  left: -1px;
  position: relative;
  top: -1px;
}

.element .page-title-2 {
  color: #fcfcfe;
  font-family: "Archivo", Helvetica;
  font-size: 12.1px;
  font-weight: 500;
  left: 20px;
  letter-spacing: -0.15px;
  line-height: normal;
  position: absolute;
  top: 16px;
  white-space: nowrap;
  width: 54px;
}

.element .rectangle-3 {
  border: 0.38px solid;
  border-color: #fcfcfe;
  height: 47px;
  left: 0;
  position: absolute;
  top: 0;
  width: 266px;
}

.element .page-title-3 {
  color: #fcfcfe;
  font-family: "Archivo", Helvetica;
  font-size: 12.1px;
  font-weight: 700;
  left: 23px;
  letter-spacing: -0.15px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 519px;
  white-space: nowrap;
  width: 268px;
}

.element .button {
  all: unset;
  background-color: #15d199;
  border-radius: 3.03px;
  box-sizing: border-box;
  height: 45px;
  left: 22px;
  position: absolute;
  top: 379px;
  width: 264px;
}

.element .page-title-4 {
  color: #ffffff;
  font-family: "Archivo", Helvetica;
  font-size: 13.6px;
  font-weight: 700;
  left: 113px;
  letter-spacing: 0.17px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 14px;
  white-space: nowrap;
  width: 38px;
}

.element .group-6 {
  height: 54px;
  left: 21px;
  position: absolute;
  top: 128px;
  width: 272px;
}

.element .page-title-5 {
  color: #ffffff;
  font-family: "Archivo", Helvetica;
  font-size: 12.1px;
  font-weight: 700;
  height: 13px;
  left: 0;
  letter-spacing: -0.15px;
  line-height: normal;
  position: absolute;
  top: 41px;
  white-space: nowrap;
  width: 268px;
}

.element .login {
  color: #fcfcfe;
  font-family: "Archivo", Helvetica;
  font-size: 25.8px;
  font-weight: 400;
  height: 27px;
  left: 0;
  letter-spacing: -0.3px;
  line-height: 26.5px;
  position: absolute;
  top: 0;
  white-space: nowrap;
  width: 165px;
}

.element .group-7 {
  height: 11px;
  left: 79px;
  position: absolute;
  top: 443px;
  width: 171px;
}

.element .text-wrapper-12 {
  color: #fcfcfe;
  font-family: "Archivo", Helvetica;
  font-size: 10.6px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0.13px;
  line-height: normal;
  position: absolute;
  top: 0;
  white-space: nowrap;
  width: 111px;
}

.element .text-wrapper-13 {
  color: #69e09a;
  font-family: "Archivo", Helvetica;
  font-size: 10.6px;
  font-weight: 400;
  left: 114px;
  letter-spacing: 0.13px;
  line-height: normal;
  position: absolute;
  top: 0;
  white-space: nowrap;
  width: 54px;
}

.element .facebook-negative {
  height: 36px !important;
  left: 139px !important;
  position: absolute !important;
  top: 563px !important;
  width: 36px !important;
}

.element .google-negative {
  height: 36px !important;
  left: 66px !important;
  position: absolute !important;
  top: 563px !important;
  width: 36px !important;
}

.element .apple-negative {
  height: 36px !important;
  left: 211px !important;
  position: absolute !important;
  top: 563px !important;
  width: 36px !important;
}

.element .line-3 {
  height: 48px;
  left: 120px;
  position: absolute;
  top: 555px;
  width: 1px;
}

.element .line-4 {
  height: 48px;
  left: 193px;
  position: absolute;
  top: 555px;
  width: 1px;
}

.element .group-8 {
  height: 22px;
  left: 23px;
  position: absolute;
  top: 45px;
  width: 89px;
}

.element .OS-homeindicator {
  height: 29px;
  left: 100px;
  position: absolute;
  top: 653px;
  width: 115px;
}

.element .home-indicator {
  background-color: #2f2f2f;
  border-radius: 85.87px;
  height: 6px;
  left: -1px;
  position: relative;
  top: 17px;
  width: 117px;
}

.element .element-signup {
  background-color: #ffffff;
  border: 10.72px solid;
  border-radius: 35.59px;
  box-shadow: 27.44px 20.58px 109.76px #cccccc2e;
  height: 722px;
  left: -21px;
  overflow: hidden;
  position: absolute;
  top: 18px;
  width: 356px;
}

.element .overlap-8 {
  height: 705px;
  left: -11px;
  position: relative;
  top: -11px;
  width: 313px;
}

.element .create-account-2 {
  background-color: #151515;
  height: 705px;
  left: 0;
  position: absolute;
  top: 0;
  width: 313px;
}

.element .group-9 {
  height: 45px;
  left: 22px;
  position: absolute;
  top: 271px;
  width: 265px;
}

.element .overlap-group-5 {
  height: 45px;
  position: relative;
  width: 263px;
}

.element .page-title-6 {
  color: #ffffff;
  font-family: "Source Sans Pro", Helvetica;
  font-size: 12.1px;
  font-weight: 400;
  left: 19px;
  letter-spacing: -0.15px;
  line-height: normal;
  position: absolute;
  top: 15px;
  width: 50px;
}

.element .rectangle-4 {
  border: 0.38px solid;
  border-color: #fcfcfe;
  height: 45px;
  left: 0;
  position: absolute;
  top: 0;
  width: 263px;
}

.element .group-10 {
  height: 45px;
  left: 22px;
  position: absolute;
  top: 330px;
  width: 265px;
}

.element .page-title-7 {
  color: #ffffff;
  font-family: "Source Sans Pro", Helvetica;
  font-size: 12.1px;
  font-weight: 400;
  left: 19px;
  letter-spacing: -0.15px;
  line-height: normal;
  position: absolute;
  top: 15px;
  width: 223px;
}

.element .group-11 {
  height: 45px;
  left: 22px;
  position: absolute;
  top: 389px;
  width: 265px;
}

.element .page-title-8 {
  color: #fcfcfe;
  font-family: "Source Sans Pro", Helvetica;
  font-size: 12.1px;
  font-weight: 400;
  left: 20px;
  letter-spacing: -0.15px;
  line-height: normal;
  position: absolute;
  top: 16px;
  width: 223px;
}

.element .rectangle-5 {
  border: 0.38px solid;
  border-color: #fcfcfe;
  height: 47px;
  left: 0;
  position: absolute;
  top: 0;
  width: 265px;
}

.element .page-title-9 {
  color: #fcfcfe;
  font-family: "Source Sans Pro", Helvetica;
  font-size: 12.1px;
  font-weight: 400;
  left: 24px;
  letter-spacing: 0.15px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 542px;
  width: 268px;
}

.element .page-title-wrapper {
  all: unset;
  background-color: #15d199;
  border-radius: 3.03px;
  box-sizing: border-box;
  height: 45px;
  left: 23px;
  position: absolute;
  top: 479px;
  width: 263px;
}

.element .page-title-10 {
  color: #ffffff;
  font-family: "Archivo", Helvetica;
  font-size: 13.6px;
  font-weight: 700;
  left: 105px;
  letter-spacing: 0.17px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 12px;
  white-space: nowrap;
  width: 53px;
}

.element .group-12 {
  height: 54px;
  left: 22px;
  position: absolute;
  top: 166px;
  width: 272px;
}

.element .login-2 {
  color: #fcfcfe;
  font-family: "Archivo", Helvetica;
  font-size: 25.7px;
  font-weight: 400;
  height: 26px;
  left: 0;
  letter-spacing: -0.3px;
  line-height: 26.5px;
  position: absolute;
  top: 0;
  white-space: nowrap;
  width: 112px;
}

.element .system-light-status {
  height: 33px;
  left: 0;
  position: absolute;
  top: 0;
  width: 313px;
}

.element .time-3 {
  color: #ffffff;
  font-family: "SF Pro Text-Semibold", Helvetica;
  font-size: 11.3px;
  font-weight: 400;
  left: 16px;
  letter-spacing: -0.23px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 10px;
  width: 41px;
}

.element .wifi-3 {
  height: 8px;
  left: 268px;
  position: absolute;
  top: 13px;
  width: 12px;
}

.element .group-13 {
  height: 22px;
  left: 23px;
  position: absolute;
  top: 45px;
  width: 88px;
}

.element .home-indicator-wrapper {
  height: 30px;
  left: 97px;
  position: absolute;
  top: 649px;
  width: 119px;
}

.element .home-indicator-2 {
  background-color: #2f2f2f;
  border-radius: 88.98px;
  height: 6px;
  left: -1px;
  position: relative;
  top: 18px;
  width: 121px;
}

.element .overlap-9 {
  height: 1853px;
  left: 0;
  position: absolute;
  top: 2093px;
  width: 1920px;
}

.element .group-14 {
  background-image: url(../../../static/img/line-193.svg);
  background-position: 50% 50%;
  background-size: cover;
  height: 1px;
  left: 1176px;
  position: absolute;
  top: 177px;
  width: 70px;
}

.element .text-wrapper-14 {
  color: #ffffff;
  font-family: "Neue Haas Grotesk Text Pro-65Medium", Helvetica;
  font-size: 28px;
  font-weight: 500;
  height: 240px;
  left: 120px;
  letter-spacing: -0.04px;
  line-height: 40px;
  position: absolute;
  top: 560px;
  width: 711px;
}

.element .head {
  height: 247px;
  left: 120px;
  position: absolute;
  top: 250px;
  width: 698px;
}

.element .watch-the-markets {
  color: transparent;
  font-family: "Neue Haas Grotesk Text Pro-Medium", Helvetica;
  font-size: 96px;
  font-weight: 500;
  height: 230px;
  left: 0;
  letter-spacing: -2.39px;
  line-height: 115px;
  position: absolute;
  top: 0;
  width: 696px;
}

.element .text-wrapper-15 {
  color: #ffffff;
}

.element .text-wrapper-16 {
  color: #15d199;
}

.element .rectangle-6 {
  background-color: #15d199;
  height: 11px;
  left: 0;
  position: absolute;
  top: 236px;
  width: 365px;
}

.element .base {
  height: 1150px;
  left: 714px;
  position: absolute;
  top: 0;
  width: 1206px;
}

.element .overlap-10 {
  height: 1150px;
  position: relative;
}

.element .base-2 {
  background-color: #262626;
  height: 1150px;
  left: 246px;
  position: absolute;
  top: 0;
  width: 960px;
}

.element .img-4 {
  height: 1150px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1206px;
}

.element .product-page {
  align-items: flex-start;
  background-color: #15d199;
  display: inline-flex;
  flex-direction: column;
  gap: 10px;
  height: 797px;
  left: 0;
  position: absolute;
  top: 1056px;
}

.element .group-15 {
  background-image: url(../../../static/img/mask-group-2.png);
  background-size: 100% 100%;
  height: 797px;
  position: relative;
  width: 1920px;
}

.element .overlap-11 {
  height: 1339px;
  left: 0;
  position: absolute;
  top: 3946px;
  width: 1920px;
}

.element .mask-group {
  height: 800px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1920px;
}

.element .text-wrapper-17 {
  color: #ffffff;
  font-family: "Neue Haas Grotesk Text Pro-65Medium", Helvetica;
  font-size: 96px;
  font-weight: 500;
  height: 332px;
  left: 120px;
  letter-spacing: -2.39px;
  line-height: 110.5px;
  position: absolute;
  top: 67px;
  width: 429px;
}

.element .outro {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 10px;
  left: 0;
  position: absolute;
  top: 799px;
  width: 1920px;
}

.element .group-wrapper {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
}

.element .group-16 {
  background-image: url(../../../static/img/rectangle-122.svg);
  background-size: 100% 100%;
  height: 540px;
  position: relative;
  width: 1920px;
}

.element .logo-wrapper {
  background-image: url(../../../static/img/mask-group.png);
  background-size: 100% 100%;
  height: 540px;
  position: relative;
}

.element .logo {
  height: 32px;
  left: 1657px;
  position: absolute;
  top: 391px;
  width: 144px;
}
