:root {
  --18pt-BLD-font-family: "Archivo-Bold", Helvetica;
  --18pt-BLD-font-size: 18px;
  --18pt-BLD-font-style: normal;
  --18pt-BLD-font-weight: 700;
  --18pt-BLD-letter-spacing: .225px;
  --18pt-BLD-line-height: normal;
  --primary: #15d199;
  --white: #fff;
}

.element {
  background-color: #262626;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  display: flex;
}

body {
  overflow-x: hidden;
}

.element .div {
  background-color: #262626;
  width: 1920px;
  height: 5285px;
  position: relative;
}

.element .overlap {
  width: 960px;
  height: 2088px;
  position: absolute;
  top: 0;
  left: 960px;
}

.element .rectangle {
  background-color: #15d199;
  width: 960px;
  height: 1233px;
  position: absolute;
  top: 0;
  left: 0;
}

.element .text-wrapper {
  color: #fff;
  letter-spacing: -.39px;
  width: 144px;
  height: 34px;
  font-family: Neue Haas Grotesk Text Pro-55Roman, Helvetica;
  font-size: 28px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 69px;
  left: 696px;
}

.element .img {
  width: 822px;
  height: 1234px;
  position: absolute;
  top: 0;
  left: 138px;
}

.element .onboarding {
  background-color: #fff;
  width: 959px;
  height: 860px;
  position: absolute;
  top: 1228px;
  left: 0;
}

.element .overlap-group {
  background-image: url("mask-group-3.548251c4.png");
  background-size: 100% 100%;
  width: 960px;
  height: 860px;
  position: relative;
  left: -1px;
}

.element .group {
  width: 215px;
  height: 33px;
  position: relative;
  top: 159px;
  left: 88px;
}

.element .text-wrapper-2 {
  color: #000;
  letter-spacing: -1.04px;
  white-space: nowrap;
  height: 33px;
  font-family: Neue Haas Grotesk Text Pro-65Medium, Helvetica;
  font-size: 32px;
  font-weight: 500;
  line-height: 32.9px;
  position: absolute;
  top: 0;
  left: 0;
}

.element .text-wrapper-3 {
  color: #fff;
  letter-spacing: -.39px;
  width: 164px;
  height: 34px;
  font-family: Neue Haas Grotesk Text Pro-55Roman, Helvetica;
  font-size: 28px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 69px;
  left: 768px;
}

.element .hero {
  width: 743px;
  height: 611px;
  position: absolute;
  top: 315px;
  left: 120px;
}

.element .p {
  color: #fff;
  letter-spacing: -.04px;
  width: 743px;
  font-family: Neue Haas Grotesk Text Pro-55Roman, Helvetica;
  font-size: 26px;
  font-weight: 400;
  line-height: 39.5px;
  position: absolute;
  top: 294px;
  left: 0;
}

.element .text-wrapper-4 {
  color: #fff;
  letter-spacing: -2.39px;
  width: 743px;
  height: 230px;
  font-family: Neue Haas Grotesk Text Pro-65Medium, Helvetica;
  font-size: 96.1px;
  font-weight: 500;
  line-height: 115px;
  position: absolute;
  top: 8px;
  left: 0;
}

.element .UIX {
  width: 160px;
  height: 56px;
  position: absolute;
  top: 1049px;
  left: 120px;
}

.element .overlap-2 {
  background-color: #5f30c5;
  border-radius: 28px;
  width: 158px;
  height: 56px;
  position: relative;
}

.element .text-wrapper-5 {
  color: #fff;
  letter-spacing: .46px;
  white-space: nowrap;
  width: 66px;
  height: 33px;
  font-family: Neue Haas Grotesk Display Pro-75Bold, Helvetica;
  font-size: 23.1px;
  font-weight: 700;
  line-height: 32.9px;
  position: absolute;
  top: 12px;
  left: 64px;
}

.element .overlap-group-wrapper {
  background-color: #f8f8f8;
  border-radius: 23.06px;
  width: 46px;
  height: 46px;
  position: absolute;
  top: 5px;
  left: 6px;
}

.element .overlap-group-2 {
  width: 21px;
  height: 21px;
  position: relative;
  top: 12px;
  left: 12px;
}

.element .line {
  width: 5px;
  height: 21px;
  position: absolute;
  top: 0;
  left: 8px;
}

.element .line-2 {
  width: 21px;
  height: 4px;
  position: absolute;
  top: 9px;
  left: 0;
}

.element .WD {
  width: 304px;
  height: 56px;
  position: absolute;
  top: 1049px;
  left: 294px;
}

.element .overlap-3 {
  background-color: #4900ff;
  border-radius: 28px;
  width: 302px;
  height: 56px;
  position: relative;
}

.element .text-wrapper-6 {
  color: #fff;
  letter-spacing: .46px;
  white-space: nowrap;
  height: 33px;
  font-family: Neue Haas Grotesk Display Pro-75Bold, Helvetica;
  font-size: 23.1px;
  font-weight: 700;
  line-height: 32.9px;
  position: absolute;
  top: 12px;
  left: 64px;
}

.element .SM {
  width: 180px;
  height: 56px;
  position: absolute;
  top: 1049px;
  left: 612px;
}

.element .overlap-4 {
  background-color: #eb4132;
  border-radius: 28px;
  width: 178px;
  height: 56px;
  position: relative;
}

.element .text-wrapper-7 {
  color: #fff;
  letter-spacing: .46px;
  white-space: nowrap;
  height: 33px;
  font-family: Neue Haas Grotesk Display Pro-75Bold, Helvetica;
  font-size: 23.1px;
  font-weight: 700;
  line-height: 32.9px;
  position: absolute;
  top: 12px;
  left: 65px;
}

.element .group-2 {
  width: 251px;
  height: 64px;
  position: absolute;
  top: 55px;
  left: 120px;
}

.element .onboarding-2 {
  background-color: #15d199;
  width: 960px;
  height: 860px;
  position: absolute;
  top: 1228px;
  left: 0;
}

.element .div-wrapper {
  width: 187px;
  height: 33px;
  position: absolute;
  top: 55px;
  left: 120px;
}

.element .text-wrapper-8 {
  color: #000;
  letter-spacing: -.04px;
  white-space: nowrap;
  height: 33px;
  font-family: Neue Haas Grotesk Text Pro-65Medium, Helvetica;
  font-size: 24px;
  font-weight: 500;
  line-height: 32.9px;
  position: absolute;
  top: 0;
  left: 0;
}

.element .group-3 {
  width: 686px;
  height: 718px;
  position: absolute;
  top: 88px;
  left: 131px;
}

.element .element-login {
  background-color: #151515;
  border: 10.78px solid #fff;
  border-radius: 34.35px;
  width: 336px;
  height: 704px;
  position: absolute;
  top: -11px;
  left: 361px;
  overflow: hidden;
  box-shadow: 27.59px 20.69px 110.34px #cccccc2e;
}

.element .overlap-5 {
  width: 314px;
  height: 706px;
  position: relative;
}

.element .UI-btn {
  width: 137px;
  height: 48px;
  position: absolute;
  top: 603px;
  left: 92px;
  box-shadow: 0 3.43px 17.17px #0000001a, 0 1.72px 3.43px #0000001a;
}

.element .overlap-6 {
  background-color: #1616cc;
  border-radius: 5.15px;
  width: 270px;
  height: 48px;
  position: relative;
  left: -64px;
}

.element .IC-chevronright {
  width: 21px;
  height: 21px;
  position: absolute;
  top: 14px;
  left: 149px;
}

.element .overlap-group-3 {
  width: 13px;
  height: 13px;
  position: relative;
  top: 3px;
  left: 4px;
}

.element .vector {
  width: 13px;
  height: 1px;
  position: absolute;
  top: 6px;
  left: 0;
}

.element .vector-2 {
  width: 7px;
  height: 13px;
  position: absolute;
  top: 0;
  left: 6px;
}

.element .text-wrapper-9 {
  color: #fff;
  width: 46px;
  font-family: Aktiv Grotesk-Bold, Helvetica;
  font-size: 15.5px;
  line-height: normal;
  position: absolute;
  top: 13px;
  left: 96px;
}

.element .group-4 {
  width: 270px;
  height: 195px;
  position: absolute;
  top: 178px;
  left: 26px;
}

.element .form-list {
  flex-direction: column;
  align-items: center;
  gap: 8.59px;
  display: inline-flex;
  position: absolute;
  top: 90px;
  left: 0;
  overflow: hidden;
}

.element .UI-form-default {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 6.87px;
  display: inline-flex;
  position: relative;
}

.element .form {
  background-color: #fff;
  flex: none;
  align-items: center;
  gap: 13.74px;
  padding: 13.74px 0;
  display: inline-flex;
  position: relative;
  overflow: hidden;
  box-shadow: inset 0 -.43px #12121d1a;
}

.element .img-2 {
  width: 20.61px;
  height: 20.61px;
  position: relative;
}

.element .fill {
  width: 238.15px;
  height: 20.61px;
  position: relative;
}

.element .text-wrapper-10 {
  color: #2f2f2f4c;
  width: 236px;
  height: 21px;
  font-family: Inter-Regular, Helvetica;
  font-size: 15.5px;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.element .divider {
  background-color: #2f2f2f4c;
  width: 270px;
  height: 1px;
  position: absolute;
  top: 65px;
  left: 0;
}

.element .social {
  align-items: center;
  gap: 13.74px;
  display: inline-flex;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.element .img-wrapper {
  background-color: #fff;
  border: .43px solid #2f2f2f1a;
  border-radius: 24.04px;
  width: 48.09px;
  height: 48.09px;
  position: relative;
}

.element .img-3 {
  width: 21px;
  height: 21px;
  position: absolute;
  top: 13px;
  left: 13px;
}

.element .title {
  flex-direction: column;
  align-items: center;
  gap: 6.87px;
  display: inline-flex;
  position: absolute;
  top: 127px;
  left: 26px;
}

.element .text-wrapper-11 {
  color: #2f2f2f;
  width: 237.24px;
  height: 24.93px;
  margin-top: -.86px;
  font-family: Inter-Bold, Helvetica;
  font-size: 20.6px;
  line-height: normal;
  position: relative;
}

.element .UI-title-nav {
  width: 270px;
  height: 38px;
  position: absolute;
  top: 64px;
  left: 26px;
}

.element .IC-close-wrapper {
  -webkit-backdrop-filter: blur(17.17px) brightness();
  backdrop-filter: blur(17.17px) brightness();
  background-color: #2f2f2f0d;
  border-radius: 18.89px;
  width: 38px;
  height: 38px;
}

.element .IC-close {
  width: 21px;
  height: 21px;
  position: relative;
  top: 9px;
  left: 9px;
}

.element .vector-wrapper {
  background-image: url("vector-1.f0073f5a.svg");
  background-size: 100% 100%;
  width: 12px;
  height: 12px;
  position: relative;
  top: 4px;
  left: 5px;
}

.element .vector-3 {
  width: 12px;
  height: 12px;
  position: absolute;
  top: 0;
  left: 0;
}

.element .create-account {
  background-color: #151515;
  width: 314px;
  height: 706px;
  position: absolute;
  top: 0;
  left: 0;
}

.element .div-2 {
  width: 314px;
  height: 33px;
  position: absolute;
  top: 0;
  left: 0;
}

.element .time {
  color: #0000;
  letter-spacing: -.23px;
  text-align: center;
  white-space: nowrap;
  width: 41px;
  font-family: SF Pro Text-Semibold, Helvetica;
  font-size: 11.4px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 10px;
  left: 16px;
}

.element .battery {
  width: 18px;
  height: 9px;
  position: absolute;
  top: 13px;
  left: 284px;
}

.element .cap {
  width: 1px;
  height: 3px;
  position: absolute;
  top: 1330px;
  left: 1272px;
}

.element .wifi {
  width: 12px;
  height: 8px;
  position: absolute;
  top: 1340px;
  left: 1523px;
}

.element .cellular-connection {
  width: 13px;
  height: 8px;
  position: absolute;
  top: 1340px;
  left: 1507px;
}

.element .time-2 {
  color: #fff;
  letter-spacing: -.23px;
  text-align: center;
  white-space: nowrap;
  width: 41px;
  font-family: SF Pro Text-Semibold, Helvetica;
  font-size: 11.4px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 10px;
  left: 16px;
}

.element .capacity-wrapper {
  border: .76px solid #ffffff59;
  border-radius: 2.02px;
  width: 17px;
  height: 9px;
  position: absolute;
  top: 0;
  left: 0;
}

.element .capacity {
  background-color: #fff;
  border-radius: 1.01px;
  width: 14px;
  height: 6px;
  position: relative;
  top: 1px;
  left: 1px;
}

.element .cap-2 {
  width: 1px;
  height: 3px;
  position: absolute;
  top: 3px;
  left: 17px;
}

.element .wifi-2 {
  width: 12px;
  height: 8px;
  position: absolute;
  top: 13px;
  left: 269px;
}

.element .cellular-connection-2 {
  width: 13px;
  height: 8px;
  position: absolute;
  top: 13px;
  left: 252px;
}

.element .group-5 {
  width: 266px;
  height: 45px;
  position: absolute;
  top: 233px;
  left: 21px;
}

.element .overlap-group-4 {
  width: 264px;
  height: 45px;
  position: relative;
}

.element .page-title {
  color: #fff;
  letter-spacing: -.15px;
  white-space: nowrap;
  width: 56px;
  font-family: Archivo, Helvetica;
  font-size: 12.1px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 15px;
  left: 19px;
}

.element .span {
  color: #fff;
  letter-spacing: -.15px;
  font-family: Archivo, Helvetica;
  font-size: 12.1px;
  font-weight: 500;
}

.element .rectangle-2 {
  border: .38px solid #fcfcfe;
  width: 264px;
  height: 45px;
  position: absolute;
  top: 0;
  left: 0;
}

.element .overlap-wrapper {
  width: 266px;
  height: 45px;
  position: absolute;
  top: 292px;
  left: 21px;
}

.element .overlap-7 {
  height: 47px;
  position: relative;
  top: -1px;
  left: -1px;
}

.element .page-title-2 {
  color: #fcfcfe;
  letter-spacing: -.15px;
  white-space: nowrap;
  width: 54px;
  font-family: Archivo, Helvetica;
  font-size: 12.1px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 16px;
  left: 20px;
}

.element .rectangle-3 {
  border: .38px solid #fcfcfe;
  width: 266px;
  height: 47px;
  position: absolute;
  top: 0;
  left: 0;
}

.element .page-title-3 {
  color: #fcfcfe;
  letter-spacing: -.15px;
  text-align: center;
  white-space: nowrap;
  width: 268px;
  font-family: Archivo, Helvetica;
  font-size: 12.1px;
  font-weight: 700;
  line-height: normal;
  position: absolute;
  top: 519px;
  left: 23px;
}

.element .button {
  all: unset;
  box-sizing: border-box;
  background-color: #15d199;
  border-radius: 3.03px;
  width: 264px;
  height: 45px;
  position: absolute;
  top: 379px;
  left: 22px;
}

.element .page-title-4 {
  color: #fff;
  letter-spacing: .17px;
  text-align: center;
  white-space: nowrap;
  width: 38px;
  font-family: Archivo, Helvetica;
  font-size: 13.6px;
  font-weight: 700;
  line-height: normal;
  position: absolute;
  top: 14px;
  left: 113px;
}

.element .group-6 {
  width: 272px;
  height: 54px;
  position: absolute;
  top: 128px;
  left: 21px;
}

.element .page-title-5 {
  color: #fff;
  letter-spacing: -.15px;
  white-space: nowrap;
  width: 268px;
  height: 13px;
  font-family: Archivo, Helvetica;
  font-size: 12.1px;
  font-weight: 700;
  line-height: normal;
  position: absolute;
  top: 41px;
  left: 0;
}

.element .login {
  color: #fcfcfe;
  letter-spacing: -.3px;
  white-space: nowrap;
  width: 165px;
  height: 27px;
  font-family: Archivo, Helvetica;
  font-size: 25.8px;
  font-weight: 400;
  line-height: 26.5px;
  position: absolute;
  top: 0;
  left: 0;
}

.element .group-7 {
  width: 171px;
  height: 11px;
  position: absolute;
  top: 443px;
  left: 79px;
}

.element .text-wrapper-12 {
  color: #fcfcfe;
  letter-spacing: .13px;
  white-space: nowrap;
  width: 111px;
  font-family: Archivo, Helvetica;
  font-size: 10.6px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.element .text-wrapper-13 {
  color: #69e09a;
  letter-spacing: .13px;
  white-space: nowrap;
  width: 54px;
  font-family: Archivo, Helvetica;
  font-size: 10.6px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 114px;
}

.element .facebook-negative {
  width: 36px !important;
  height: 36px !important;
  position: absolute !important;
  top: 563px !important;
  left: 139px !important;
}

.element .google-negative {
  width: 36px !important;
  height: 36px !important;
  position: absolute !important;
  top: 563px !important;
  left: 66px !important;
}

.element .apple-negative {
  width: 36px !important;
  height: 36px !important;
  position: absolute !important;
  top: 563px !important;
  left: 211px !important;
}

.element .line-3 {
  width: 1px;
  height: 48px;
  position: absolute;
  top: 555px;
  left: 120px;
}

.element .line-4 {
  width: 1px;
  height: 48px;
  position: absolute;
  top: 555px;
  left: 193px;
}

.element .group-8 {
  width: 89px;
  height: 22px;
  position: absolute;
  top: 45px;
  left: 23px;
}

.element .OS-homeindicator {
  width: 115px;
  height: 29px;
  position: absolute;
  top: 653px;
  left: 100px;
}

.element .home-indicator {
  background-color: #2f2f2f;
  border-radius: 85.87px;
  width: 117px;
  height: 6px;
  position: relative;
  top: 17px;
  left: -1px;
}

.element .element-signup {
  background-color: #fff;
  border: 10.72px solid;
  border-radius: 35.59px;
  width: 356px;
  height: 722px;
  position: absolute;
  top: 18px;
  left: -21px;
  overflow: hidden;
  box-shadow: 27.44px 20.58px 109.76px #cccccc2e;
}

.element .overlap-8 {
  width: 313px;
  height: 705px;
  position: relative;
  top: -11px;
  left: -11px;
}

.element .create-account-2 {
  background-color: #151515;
  width: 313px;
  height: 705px;
  position: absolute;
  top: 0;
  left: 0;
}

.element .group-9 {
  width: 265px;
  height: 45px;
  position: absolute;
  top: 271px;
  left: 22px;
}

.element .overlap-group-5 {
  width: 263px;
  height: 45px;
  position: relative;
}

.element .page-title-6 {
  color: #fff;
  letter-spacing: -.15px;
  width: 50px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 12.1px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 15px;
  left: 19px;
}

.element .rectangle-4 {
  border: .38px solid #fcfcfe;
  width: 263px;
  height: 45px;
  position: absolute;
  top: 0;
  left: 0;
}

.element .group-10 {
  width: 265px;
  height: 45px;
  position: absolute;
  top: 330px;
  left: 22px;
}

.element .page-title-7 {
  color: #fff;
  letter-spacing: -.15px;
  width: 223px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 12.1px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 15px;
  left: 19px;
}

.element .group-11 {
  width: 265px;
  height: 45px;
  position: absolute;
  top: 389px;
  left: 22px;
}

.element .page-title-8 {
  color: #fcfcfe;
  letter-spacing: -.15px;
  width: 223px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 12.1px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 16px;
  left: 20px;
}

.element .rectangle-5 {
  border: .38px solid #fcfcfe;
  width: 265px;
  height: 47px;
  position: absolute;
  top: 0;
  left: 0;
}

.element .page-title-9 {
  color: #fcfcfe;
  letter-spacing: .15px;
  text-align: center;
  width: 268px;
  font-family: Source Sans Pro, Helvetica;
  font-size: 12.1px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 542px;
  left: 24px;
}

.element .page-title-wrapper {
  all: unset;
  box-sizing: border-box;
  background-color: #15d199;
  border-radius: 3.03px;
  width: 263px;
  height: 45px;
  position: absolute;
  top: 479px;
  left: 23px;
}

.element .page-title-10 {
  color: #fff;
  letter-spacing: .17px;
  text-align: center;
  white-space: nowrap;
  width: 53px;
  font-family: Archivo, Helvetica;
  font-size: 13.6px;
  font-weight: 700;
  line-height: normal;
  position: absolute;
  top: 12px;
  left: 105px;
}

.element .group-12 {
  width: 272px;
  height: 54px;
  position: absolute;
  top: 166px;
  left: 22px;
}

.element .login-2 {
  color: #fcfcfe;
  letter-spacing: -.3px;
  white-space: nowrap;
  width: 112px;
  height: 26px;
  font-family: Archivo, Helvetica;
  font-size: 25.7px;
  font-weight: 400;
  line-height: 26.5px;
  position: absolute;
  top: 0;
  left: 0;
}

.element .system-light-status {
  width: 313px;
  height: 33px;
  position: absolute;
  top: 0;
  left: 0;
}

.element .time-3 {
  color: #fff;
  letter-spacing: -.23px;
  text-align: center;
  width: 41px;
  font-family: SF Pro Text-Semibold, Helvetica;
  font-size: 11.3px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 10px;
  left: 16px;
}

.element .wifi-3 {
  width: 12px;
  height: 8px;
  position: absolute;
  top: 13px;
  left: 268px;
}

.element .group-13 {
  width: 88px;
  height: 22px;
  position: absolute;
  top: 45px;
  left: 23px;
}

.element .home-indicator-wrapper {
  width: 119px;
  height: 30px;
  position: absolute;
  top: 649px;
  left: 97px;
}

.element .home-indicator-2 {
  background-color: #2f2f2f;
  border-radius: 88.98px;
  width: 121px;
  height: 6px;
  position: relative;
  top: 18px;
  left: -1px;
}

.element .overlap-9 {
  width: 1920px;
  height: 1853px;
  position: absolute;
  top: 2093px;
  left: 0;
}

.element .group-14 {
  background-image: url("line-193.87ff9664.svg");
  background-position: 50%;
  background-size: cover;
  width: 70px;
  height: 1px;
  position: absolute;
  top: 177px;
  left: 1176px;
}

.element .text-wrapper-14 {
  color: #fff;
  letter-spacing: -.04px;
  width: 711px;
  height: 240px;
  font-family: Neue Haas Grotesk Text Pro-65Medium, Helvetica;
  font-size: 28px;
  font-weight: 500;
  line-height: 40px;
  position: absolute;
  top: 560px;
  left: 120px;
}

.element .head {
  width: 698px;
  height: 247px;
  position: absolute;
  top: 250px;
  left: 120px;
}

.element .watch-the-markets {
  color: #0000;
  letter-spacing: -2.39px;
  width: 696px;
  height: 230px;
  font-family: Neue Haas Grotesk Text Pro-Medium, Helvetica;
  font-size: 96px;
  font-weight: 500;
  line-height: 115px;
  position: absolute;
  top: 0;
  left: 0;
}

.element .text-wrapper-15 {
  color: #fff;
}

.element .text-wrapper-16 {
  color: #15d199;
}

.element .rectangle-6 {
  background-color: #15d199;
  width: 365px;
  height: 11px;
  position: absolute;
  top: 236px;
  left: 0;
}

.element .base {
  width: 1206px;
  height: 1150px;
  position: absolute;
  top: 0;
  left: 714px;
}

.element .overlap-10 {
  height: 1150px;
  position: relative;
}

.element .base-2 {
  background-color: #262626;
  width: 960px;
  height: 1150px;
  position: absolute;
  top: 0;
  left: 246px;
}

.element .img-4 {
  width: 1206px;
  height: 1150px;
  position: absolute;
  top: 0;
  left: 0;
}

.element .product-page {
  background-color: #15d199;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  height: 797px;
  display: inline-flex;
  position: absolute;
  top: 1056px;
  left: 0;
}

.element .group-15 {
  background-image: url("mask-group-2.021737ea.png");
  background-size: 100% 100%;
  width: 1920px;
  height: 797px;
  position: relative;
}

.element .overlap-11 {
  width: 1920px;
  height: 1339px;
  position: absolute;
  top: 3946px;
  left: 0;
}

.element .mask-group {
  width: 1920px;
  height: 800px;
  position: absolute;
  top: 0;
  left: 0;
}

.element .text-wrapper-17 {
  color: #fff;
  letter-spacing: -2.39px;
  width: 429px;
  height: 332px;
  font-family: Neue Haas Grotesk Text Pro-65Medium, Helvetica;
  font-size: 96px;
  font-weight: 500;
  line-height: 110.5px;
  position: absolute;
  top: 67px;
  left: 120px;
}

.element .outro {
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  width: 1920px;
  display: flex;
  position: absolute;
  top: 799px;
  left: 0;
}

.element .group-wrapper {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  display: inline-flex;
  position: relative;
}

.element .group-16 {
  background-image: url("rectangle-122.2356f5d3.svg");
  background-size: 100% 100%;
  width: 1920px;
  height: 540px;
  position: relative;
}

.element .logo-wrapper {
  background-image: url("mask-group.b8dbe5aa.png");
  background-size: 100% 100%;
  height: 540px;
  position: relative;
}

.element .logo {
  width: 144px;
  height: 32px;
  position: absolute;
  top: 391px;
  left: 1657px;
}

/*# sourceMappingURL=index.1fdfb038.css.map */
