:root {
  --18pt-BLD-font-family: "Archivo-Bold", Helvetica;
  --18pt-BLD-font-size: 18px;
  --18pt-BLD-font-style: normal;
  --18pt-BLD-font-weight: 700;
  --18pt-BLD-letter-spacing: 0.22499999403953552px;
  --18pt-BLD-line-height: normal;
  --primary: rgba(21, 209, 153, 1);
  --white: rgba(255, 255, 255, 1);
}
